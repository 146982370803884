<template>
  <page-layout>
    <v-container>
      <v-row>
        <v-col
          offset-md="10"
          offset-lg="10"
          offset-xl="10"
          md="2"
          lg="2"
          xl="2"
        >
          <v-btn elevation="3" @click="exportExcel"> Excel'e Aktar </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        class="elevation-1 mt-3"
        :headers="headers"
        :items="candidates"
        :items-per-page="15"
        :loading="isLoading"
      />
    </v-container>
  </page-layout>
</template>
<script>
import axios from '@axios';
import { json2excel } from 'js2excel';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'Adaylar',
  components: { PageLayout },
  data: () => ({
    isLoading: true,
    candidates: [],
    headers: [
      {
        text: 'T.C.',
        value: 'idNumber',
        sortable: false
      },
      {
        text: 'İsim',
        value: 'name',
        sortable: false
      },
      {
        text: 'Soyisim',
        value: 'lastname',
        sortable: false
      },
      {
        text: 'Telefon',
        value: 'phone',
        sortable: false
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false
      }
    ]
  }),
  mounted() {
    this.listReport();
  },
  methods: {
    listReport() {
      axios.get(`report-firm-candidate`).then((response) => {
        this.isLoading = false;
        const items = response.data;
        this.candidates = items;
      });
    },
    exportExcel() {
      try {
        json2excel({
          data: this.candidates,
          name: 'report-firm-candidates',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        console.log(e);
        this.$toast.error(
          'Excel Aktarım Hatası.\nTablonun Dolu olduğuna emin olun'
        );
      }
    }
  }
};
</script>

<style lang="scss"></style>
